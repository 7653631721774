const fontSize = {
  base: '16px',

  mainText: '0.875rem',

  h1: '1.5rem',
  h2: '1.25rem',
  h3: '1.125rem',
  h4: '1rem',
  h5: '1rem',
  h6: '0.875rem',

  subtitle1: '0.75rem',

  sideMenuMainHeader: '1.25rem',
  sideMenuSecondaryHeader: '0.75rem',

  tableHeader: '0.625rem',
};

const fontFamily =
  "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif";

const fontWeight = {
  regular: 400,
  bold: 700,
};

export { fontSize, fontFamily, fontWeight };
