import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { useDeviceType } from 'providers/DeviceTypeProvider';
import useCurrentUser from 'hooks/useCurrentUser';
import useCurrentUserInfo from 'hooks/useCurrentUserInfo';

import {
  AdministratorInformation,
  CustomerUserInformation,
} from 'types/userInfo';
import { DeviceType } from 'types/deviceTypes';

import SideMenu from 'components/side-menu/SideMenu';
import TopMenu from 'components/side-menu/TopMenu';
import FooterMenu from 'components/side-menu/FooterMenu';

import { getNavigationLinks } from 'utils/getNavigationLinks';
import CommonFooter from 'components/page/CommonFooter';
import { paths } from 'constants/paths';

const StyledMain = styled.main<{ $mobile: boolean }>`
  height: 100%;

  display: flex;
  overflow: hidden;
  ${(prop) => (prop.$mobile ? 'flex-direction: column' : 'flex-direction: row')}
`;

const StyledMenu = styled(SideMenu)`
  flex: 0 0 auto;
`;

const StyledTopMenu = styled(TopMenu)`
  flex: 0 0 auto;
`;

const StyledFooterMenu = styled(FooterMenu)`
  flex: 0 0 auto;
`;

const MainContentWrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

const DesktopFooter = styled(CommonFooter)`
  flex: 0 0 auto;
  margin-top: auto;
`;

const MobileLayout = ({ children }: { children: ReactNode }) => {
  const { user } = useCurrentUser();
  const { user: userInfo } = useCurrentUserInfo<
    CustomerUserInformation | AdministratorInformation
  >();
  const location = useLocation();

  const isAdminMenu = user?.role === 'Administrator';
  const isWizard =
    location.pathname.startsWith(paths.customer.manualInput) ||
    location.pathname.startsWith(paths.customer.createSession);

  return (
    <StyledMain $mobile>
      {user && userInfo && !isAdminMenu && !isWizard && <StyledTopMenu />}
      <MainContentWrapper>{children}</MainContentWrapper>
      {user && userInfo && !isWizard && (
        <StyledFooterMenu
          navigationLinks={getNavigationLinks(user.role, userInfo)}
        />
      )}
    </StyledMain>
  );
};

const DesktopLayout = ({ children }: { children: ReactNode }) => {
  const { user } = useCurrentUser();
  const { user: userInfo } = useCurrentUserInfo<
    CustomerUserInformation | AdministratorInformation
  >();
  const isAdminMenu = user?.role === 'Administrator';

  return (
    <StyledMain $mobile={false}>
      {user && userInfo && (
        <StyledMenu
          isCollapsible={!isAdminMenu}
          navigationLinks={getNavigationLinks(user.role, userInfo)}
        />
      )}
      <MainContentWrapper>
        {children}
        <DesktopFooter />
      </MainContentWrapper>
    </StyledMain>
  );
};

const ApplicationLayout = ({ children }: { children: ReactNode }) => {
  const { user } = useCurrentUser();
  const deviceType = useDeviceType();

  return (
    <>
      {deviceType === DeviceType.Desktop || user?.role === 'Administrator' ? (
        <DesktopLayout>{children}</DesktopLayout>
      ) : (
        <MobileLayout>{children}</MobileLayout>
      )}
    </>
  );
};

const AssessmentLayout = ({ children }: { children: ReactNode }) => {
  return (
    <StyledMain $mobile={false}>
      <MainContentWrapper>{children}</MainContentWrapper>
    </StyledMain>
  );
};

export { ApplicationLayout, AssessmentLayout };
