import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { colors, fontWeight } from 'styles';

import { useShowAlert } from 'hooks/useShowAlert';
import { post } from 'apis/api';
import { paths } from 'constants/paths';
import { Registration as RegistrationTypes } from 'types/registration';

import RegistrationContent from './components/RegistrationContent';
import { ValidationError } from 'types/errors';
import PanePageLayout from 'components/page/PanePageLayout';

const LoginLabel = styled.p`
  margin: 0.5rem 0 0;
  text-align: center;

  & a {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
    text-decoration: none;
  }
`;

const Registration = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const { showError } = useShowAlert();

  const [page, setPage] = useState<'company' | 'user'>('company');

  const handleRegister = async (values: RegistrationTypes) => {
    if ('company' === page) {
      setPage('user');
    } else {
      try {
        await post('/registrations', values);
        history.push(paths.app.login);
      } catch (err) {
        if (err instanceof ValidationError) {
          const validationError = err as ValidationError;
          if (validationError.errors.length) {
            showError(t(`ServerErrors.${validationError.errors[0].code}`));
          }
        } else {
          showError(err);
        }
      }
    }
  };

  return (
    <PanePageLayout heading={t('Registration.PageHeading')}>
      <RegistrationContent
        handleSubmit={handleRegister}
        page={page}
        onSetPage={(page) => setPage(page)}
      />
      <LoginLabel>
        {t('Registration.AlreadyRegisteredLabel')}&nbsp;
        <Link to={paths.app.login}>{t('Registration.LoginLink')}</Link>
      </LoginLabel>
    </PanePageLayout>
  );
};

export default Registration;
