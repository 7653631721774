import { createContext, useContext, useLayoutEffect, useState } from 'react';
import { DeviceType } from 'types/deviceTypes';

import { breakpoints } from 'styles';

const DeviceTypeContext = createContext<DeviceType>(DeviceType.Mobile);

export const DeviceTypeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.Mobile);

  useLayoutEffect(() => {
    const selectDeviceType = () =>
      setDeviceType(
        window.innerWidth >= breakpoints['lg']
          ? DeviceType.Desktop
          : DeviceType.Mobile
      );

    selectDeviceType();
    window.addEventListener('resize', selectDeviceType);

    return () => window.removeEventListener('resize', selectDeviceType);
  }, []);

  return (
    <DeviceTypeContext.Provider value={deviceType}>
      {children}
    </DeviceTypeContext.Provider>
  );
};

export const useDeviceType = () => useContext(DeviceTypeContext);
