import useSWR from 'swr';

import { Roles } from 'types/users';

export const useCustomerRolesList = () => {
  const { data, error } = useSWR<Roles[]>(`users/roles`);

  return {
    roles: data,
    isLoading: !data && !error,
  };
};
