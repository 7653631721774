import { useTranslation } from 'react-i18next';

import { Snackbar as MuiSnackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { AlertType } from 'types/alerts';

type SnackbarProps = {
  open: boolean;
  onClose(): void;
  onExited?(): void;
  message?: string;
  type?: AlertType;
  shouldAutoHide?: boolean;
};

const Snackbar = ({
  open,
  onClose,
  onExited,
  message,
  type,
  shouldAutoHide = true,
}: SnackbarProps) => {
  const { t } = useTranslation('common');

  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  let alertSeverity: 'error' | 'warning' = 'error';
  switch (type) {
    case AlertType.Error:
      alertSeverity = 'error';
      break;
    case AlertType.Warning:
      alertSeverity = 'warning';
      break;
  }

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={handleClose}
      onExited={onExited}
      open={open}
      autoHideDuration={shouldAutoHide ? 5000 : null}
    >
      <Alert
        severity={alertSeverity}
        onClick={handleClose}
        action={
          <IconButton
            size="small"
            aria-label={t('Snackbar.CloseServerErrorButton')}
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
