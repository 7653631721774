import { useTranslation } from 'react-i18next';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { AlertProvider } from 'providers/AlertProvider';
import { InstanceProvider } from 'providers/InstanceProvider';
import { DeviceTypeProvider } from 'providers/DeviceTypeProvider';
import { dateLocales } from 'utils/dateFunctions';

import App from './App';

const AppWrapper = () => {
  const { i18n } = useTranslation();

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={dateLocales[i18n.languages[0]]}
    >
      <AlertProvider>
        <InstanceProvider>
          <DeviceTypeProvider>
            <App />
          </DeviceTypeProvider>
        </InstanceProvider>
      </AlertProvider>
    </MuiPickersUtilsProvider>
  );
};

export default AppWrapper;
