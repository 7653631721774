import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ListItem, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import { colors } from 'styles';
import { NavigationLink } from 'types/navigationLink';

import SideMenuLink from './SideMenuLink';

const MoreListItem = styled(ListItem)`
  ${(props) => props.hidden && 'visibility: hidden'}
`;

const MoreItemIcon = styled(ListItemIcon)`
  color: ${colors.accentPrimaryText};
`;

const MoreMenuItems = React.forwardRef<
  HTMLDivElement,
  { navigationLinks: NavigationLink[] }
>(({ navigationLinks }, ref) => {
  const { t } = useTranslation('common');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MoreListItem
        button
        disableGutters
        onClick={handleClick}
        ref={ref}
        hidden={!navigationLinks.length}
      >
        <MoreItemIcon>
          <MenuIcon />
        </MoreItemIcon>
      </MoreListItem>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!navigationLinks.length && Boolean(anchorEl)}
        onClose={handleClose}
      >
        {navigationLinks.map(({ path, name }) => (
          <MenuItem
            key={path}
            onClick={handleClose}
            component={SideMenuLink}
            to={path}
            exact={path === '/' ? true : undefined}
          >
            {t(`Menu.${name}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default MoreMenuItems;
