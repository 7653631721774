import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';

import { defaultTheme, colors, fontSize, fontFamily } from './styles';

import FullScreenLoading from 'components/page/FullScreenLoading';

import AppWrapper from 'AppWrapper';
import './i18n';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }

  body {
    background-color: ${colors.appBackground};
    color: ${colors.mainText};
    font-size: ${fontSize.base};
    font-family: ${fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    & > div#root {
      height: 100%;
      width: 100%;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle />
      <StylesProvider injectFirst>
        <MaterialThemeProvider theme={defaultTheme}>
          <Suspense fallback={<FullScreenLoading />}>
            <AppWrapper />
          </Suspense>
        </MaterialThemeProvider>
      </StylesProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
