export const paths = {
  app: {
    customer: '/',
    admin: '/admin',
    login: '/login',
    registration: '/registration',
    assessment: '/assessment',
    completeRegistration: '/complete-registration',
    confirmEmail: '/confirm-email',
    forgotPassword: '/forgot-password',
    changePassword: '/change-password',
  },
  admin: {
    requests: '/admin/requests',
    companies: '/admin/companies',
    users: '/admin/users',
    administrators: '/admin/administrators',
    instruments: '/admin/instruments',
    instances: '/admin/instances',
    emailHistory: '/admin/email-history',
    profile: '/admin/profile',
  },
  customer: {
    dashboard: '/',
    results: '/results',
    sessions: '/sessions',
    credits: '/credits',
    respondents: '/respondents',
    batches: '/batches',
    templates: '/templates',
    users: '/users',
    createSession: '/create-session',
    manualInput: '/manual-input',
    profile: '/profile',
  },
};
