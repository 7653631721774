import React, { FC, useState } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { NavigationLink } from 'types/navigationLink';
import { colors, defaultBorder, fontWeight, zIndex } from 'styles';

import LanguageSelector from './components/LanguageSelector';
import AppLogo from 'components/app-logo/AppLogo';
import ProfileButton from './components/ProfileButton';

type SideMenuSectionProps = {
  collapsed: boolean;
};

const SideMenuSection = styled.section`
  width: ${(props: SideMenuSectionProps) =>
    props.collapsed ? '3.5rem' : '13.375rem'};
  background-color: ${colors.accentBackground};
  color: ${colors.accentPrimaryText};

  display: flex;
  flex-direction: column;
`;

const SideMenuLogoHeader = styled(AppLogo)`
  padding: 1rem;

  .MuiTypography-subtitle1 {
    color: ${colors.accentSecondaryText};
  }
`;

const CollapseButtonWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid ${colors.sideMenuHeaderBorder};
`;

const CollapseButton = styled(IconButton)`
  border: ${defaultBorder};
  background-color: ${colors.background};
  color: ${colors.primary};
  padding: 0;

  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  z-index: ${zIndex.overContent};

  &:hover {
    background-color: ${colors.background};
  }
`;

const NavigationMenuList = styled(List)`
  margin: 1rem 0;

  & .MuiListItemIcon-root,
  .MuiListItemText-root {
    color: ${colors.accentSecondaryText};
  }

  & .MuiListItemIcon-root {
    min-width: 1.5rem;
    padding-right: 0.75rem;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  & > .active .MuiListItemIcon-root,
  > .active .MuiListItemText-root {
    color: ${colors.accentPrimaryText};
    font-weight: ${fontWeight.bold};
  }
`;

const StyledLanguageSelector = styled(LanguageSelector)`
  align-self: flex-start;
  margin: ${({ collapsed }) =>
    collapsed ? 'auto 0.5rem 0 0.5rem' : 'auto 1rem 0 1rem'};
`;

const StyledProfileButton = styled(ProfileButton)`
  margin: 0.5rem 1rem 6rem;
`;

type SideMenuProps = {
  isCollapsible: boolean;
  navigationLinks: NavigationLink[];
};

const SideMenuLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ to, ...rest }: NavLinkProps, ref) =>
    to.toString().startsWith('http') ? (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        href={to.toString()}
        target="_blank"
        rel="noreferrer"
        {...rest}
        ref={ref}
      />
    ) : (
      <NavLink to={to} {...rest} ref={ref} />
    )
);

const SideMenu: FC<SideMenuProps> = ({
  isCollapsible,
  navigationLinks,
  ...props
}) => {
  const { t } = useTranslation('common');

  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <SideMenuSection collapsed={isCollapsed} {...props}>
      <SideMenuLogoHeader short={isCollapsed} forwardedAs="header" />
      {isCollapsible && (
        <CollapseButtonWrapper>
          <CollapseButton
            aria-label={t('Menu.CollapseButton')}
            onClick={() => {
              setCollapsed((collapsed) => !collapsed);
            }}
          >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </CollapseButton>
        </CollapseButtonWrapper>
      )}
      <NavigationMenuList as="nav">
        {navigationLinks.map(({ path, name, icon }) => (
          <ListItem
            key={path}
            button
            component={SideMenuLink}
            to={path}
            exact={path === '/' ? true : undefined}
          >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            {!isCollapsed && <ListItemText>{t(`Menu.${name}`)}</ListItemText>}
          </ListItem>
        ))}
      </NavigationMenuList>
      <StyledLanguageSelector collapsed={isCollapsed} />
      <StyledProfileButton collapsed={isCollapsed} />
    </SideMenuSection>
  );
};

export default SideMenu;
