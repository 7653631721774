import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const SideMenuLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ to, ...rest }: NavLinkProps, ref) =>
    to.toString().startsWith('http') ? (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        href={to.toString()}
        target="_blank"
        rel="noreferrer"
        {...rest}
        ref={ref}
      />
    ) : (
      <NavLink to={to} {...rest} ref={ref} />
    )
);

export default SideMenuLink;
