import { FC, createContext, useEffect, useState } from 'react';

import { getCurrentInstance } from 'apis/instance';
import { InstanceInformation } from 'types/instances';

import FullScreenLoading from 'components/page/FullScreenLoading';

export const InstanceContext = createContext<InstanceInformation | null>(null);

export const InstanceProvider: FC = ({ children }) => {
  const [instance, setInstance] = useState<InstanceInformation | null>(null);

  useEffect(() => {
    (async () => {
      var instanceInfo = {
        defaultLanguage: 'en',
        availableLanguages: ['en'],
      } as InstanceInformation;

      try {
        instanceInfo = await getCurrentInstance();
        setInstance(instanceInfo);
      } catch {
        setInstance(instanceInfo);
      }
    })();
  }, []);

  return (
    <>
      <InstanceContext.Provider value={instance}>
        {instance ? children : <FullScreenLoading />}
      </InstanceContext.Provider>
    </>
  );
};
