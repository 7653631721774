import { createContext, useState, useEffect, useCallback } from 'react';
import { AlertType, SnackbarMessage } from 'types/alerts';
import Snackbar from 'components/alerts/Snackbar';

export const AlertContext = createContext(
  (message: string, type?: AlertType, shouldAutoHide?: boolean) => {}
);

type AlertProviderProps = {
  children: React.ReactNode;
};

const useSnackbarAlert = () => {
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage>();

  const showAlert = useCallback(
    (
      message: string,
      type: AlertType = AlertType.Error,
      shouldAutoHide: boolean = true
    ) => {
      setSnackPack((prev) => [
        ...prev,
        { message, key: new Date().getTime(), type, shouldAutoHide },
      ]);
    },
    []
  );

  const handleExited = useCallback(() => {
    setMessageInfo(undefined);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  return {
    open,
    message: messageInfo,
    showAlert,
    handleExited,
    handleClose,
  };
};

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const {
    open,
    message,
    showAlert,
    handleClose,
    handleExited,
  } = useSnackbarAlert();

  return (
    <>
      <AlertContext.Provider value={showAlert}>
        {children}
      </AlertContext.Provider>
      <Snackbar
        key={message?.key}
        open={open}
        message={message?.message}
        type={message?.type}
        shouldAutoHide={message?.shouldAutoHide}
        onClose={handleClose}
        onExited={handleExited}
      />
    </>
  );
};
