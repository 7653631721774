import { NavigationLink } from 'types/navigationLink';
import { Role } from 'types/auth';
import {
  AdministratorInformation,
  CustomerUserInformation,
} from 'types/userInfo';

import { paths } from 'constants/paths';

import {
  Aseba,
  Batches,
  Credits,
  Dashboard,
  Manual,
  Respondents,
  Results,
  Sessions,
  Templates,
  Users,
} from 'components/icons/icons';

import { generateNakladaSlapRedirectUrl } from './redirectFunctions';

export const getNavigationLinks = (
  role: Role,
  userInfo: CustomerUserInformation | AdministratorInformation
) => {
  let links: Array<NavigationLink> = [];

  if (role === 'Administrator') {
    const info = userInfo as AdministratorInformation;

    links = [
      { name: 'Requests', path: paths.admin.requests },
      { name: 'Companies', path: paths.admin.companies },
      { name: 'CustomerUsers', path: paths.admin.users },
    ];

    if (info.canManageAdministrators) {
      links.push({ name: 'Administrators', path: paths.admin.administrators });
    }

    if (info.canImportInstruments) {
      links.push({ name: 'Instruments', path: paths.admin.instruments });
    }

    links.push({ name: 'Instances', path: paths.admin.instances });

    if (info.canManageAdministrators && info.canImportInstruments) {
      links.push({ name: 'EmailHistory', path: paths.admin.emailHistory });
    }
  } else if (role === 'Customer') {
    const info = userInfo as CustomerUserInformation;

    links = [
      {
        name: 'Dashboard',
        path: paths.customer.dashboard,
        icon: <Dashboard />,
      },
    ];

    if (info.canManageRespondents) {
      links.push({
        name: 'Respondents',
        path: paths.customer.respondents,
        icon: <Respondents />,
      });
    }

    if (info.canStartAssessment) {
      links.push({
        name: 'Sessions',
        path: paths.customer.sessions,
        icon: <Sessions />,
      });
    }

    if (info.canViewResults) {
      links.push({
        name: 'Results',
        path: paths.customer.results,
        icon: <Results />,
      });
    }

    links.push({
      name: 'Credits',
      path: paths.customer.credits,
      icon: <Credits />,
    });

    if (info.canManageBatches) {
      links.push({
        name: 'Batches',
        path: paths.customer.batches,
        icon: <Batches />,
      });
    }

    if (info.canManageEmailTemplates || info.canManageMessageTemplates) {
      links.push({
        name: 'Templates',
        path: paths.customer.templates,
        icon: <Templates />,
      });
    }

    if (info.canManageUsers) {
      links.push({
        name: 'Users',
        path: paths.customer.users,
        icon: <Users />,
      });
    }

    links.push({
      name: 'Manual',
      path: generateNakladaSlapRedirectUrl('manual'),
      icon: <Manual />,
    });

    links.push({
      name: 'ASEBA',
      path: generateNakladaSlapRedirectUrl('ASEBA'),
      icon: <Aseba />,
    });
  }

  return links;
};
