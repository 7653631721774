import { SelectOption } from 'components/controls/select/Select';

type StandardEnumLike<T> = {
  [id: string]: T | string;
  [num: number]: string;
};

export function enumKeysAsTranslatedSelectOptions<
  E extends StandardEnumLike<string | number>
>(e: E, translateFn: (key: string) => string): SelectOption[] {
  const arrayObjects: SelectOption[] = [];
  for (const [key, value] of Object.entries(e)) {
    if (!Number.isNaN(Number(key))) {
      continue;
    }
    arrayObjects.push({ value, name: translateFn(key) });
  }
  return arrayObjects;
}
