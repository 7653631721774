import { FC, ReactElement } from 'react';
import { useField } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';

const FormTextField: FC<
  { name: string; silentErrors?: boolean } & Omit<
    TextFieldProps,
    'name' | 'value' | 'onChange' | 'onBlur'
  >
> = ({ name, silentErrors, ...rest }): ReactElement => {
  const [field, meta] = useField<string>(name);

  const { error, touched } = meta;

  return (
    <TextField
      {...rest}
      id={`form-field-${name}`}
      {...field}
      error={Boolean(touched && error)}
      helperText={!silentErrors && touched && error}
    />
  );
};

export default FormTextField;
