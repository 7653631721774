export enum AlertType {
  Error,
  Warning,
  Success,
}

export interface SnackbarMessage {
  message: string;
  key: number;
  type?: AlertType;
  shouldAutoHide?: boolean;
}
