import {
  AdministratorInformation,
  CustomerUserInformation,
} from 'types/userInfo';
import { get } from 'apis/api';

async function getCustomerUserInfo() {
  const response = await get('/users/information');
  return (await response.json()) as CustomerUserInformation;
}

async function getAdministratorInfo() {
  const response = await get('/administrators/information');
  return (await response.json()) as AdministratorInformation;
}

export { getCustomerUserInfo, getAdministratorInfo };
