import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import { fontWeight, colors, fontSize } from 'styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RegistrationCompanyForm from './RegistrationCompanyForm';
import RegistrationUserForm from './RegistrationUserForm';

import { Registration } from 'types/registration';
import { trimObjectProperties } from 'utils/objectFunctions';

const phoneRegExp = /^[+]?[-\s.0-9]*?[(]{0,1}[0-9]+[)]{0,1}[-\s./0-9]*$/;

const yearRegExp = /^(19|20)\d{2}$/;

const trimmedString = yup.string().trim();

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 16rem;

  & .MuiTypography-h2 {
    font-weight: ${fontWeight.regular};
    text-align: center;
    font-size: ${fontSize.h6};
    margin-top: 0.625rem;
  }

  & .MuiFormControl-root,
  .MuiButton-root {
    margin-top: 1.25rem;
  }
`;

const FormHeading = styled.div`
  font-weight: ${fontWeight.bold};
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: ${colors.headerText};
  margin-top: 0.625rem;
`;

type RegistrationContentProps = {
  handleSubmit(values: Registration): Promise<void>;
  page: 'company' | 'user';
  onSetPage(page: 'company' | 'user'): void;
};

const RegistrationContent: FC<RegistrationContentProps> = ({
  handleSubmit,
  page,
  onSetPage,
}): ReactElement => {
  const { t } = useTranslation('common');

  const initialValues: Registration = {
    organizationName: '',
    vatId: '',
    address: '',
    city: '',
    zipCode: '',
    phone: '',
    fax: '',
    email: '',
    adminUser: {
      nakladaSlapId: '',
      firstName: '',
      lastName: '',
      email: '',
      occupation: '',
      workingPlace: '',
      education: '',
      graduatedFrom: '',
      graduationYear: undefined,
      phone: '',
      contractNumber: '',
    },
  };

  const companyValidationSchema = yup.object({
    organizationName: trimmedString
      .required(t('Errors.Required'))
      .max(100, t('Errors.MaxCharacters', { count: 100 })),
    vatId: trimmedString
      .required(t('Errors.Required'))
      .max(20, t('Errors.MaxCharacters', { count: 20 })),
    address: trimmedString
      .required(t('Errors.Required'))
      .max(200, t('Errors.MaxCharacters', { count: 200 })),
    city: trimmedString
      .required(t('Errors.Required'))
      .max(100, t('Errors.MaxCharacters', { count: 100 })),
    zipCode: trimmedString
      .required(t('Errors.Required'))
      .max(20, t('Errors.MaxCharacters', { count: 20 })),
    phone: trimmedString
      .required(t('Errors.Required'))
      .max(50, t('Errors.MaxCharacters', { count: 50 }))
      .matches(phoneRegExp, t('Errors.NotValidPhoneNumber')),
    fax: trimmedString.max(50, t('Errors.MaxCharacters', { count: 50 })),
    email: trimmedString
      .required(t('Errors.Required'))
      .email(t('Errors.NotValidEmail'))
      .max(254, t('Errors.MaxCharacters', { count: 254 })),
  });

  const userValidationSchema = yup.object({
    adminUser: yup.object({
      nakladaSlapId: trimmedString.max(
        32,
        t('Errors.MaxCharacters', { count: 32 })
      ),
      firstName: trimmedString
        .required(t('Errors.Required'))
        .max(100, t('Errors.MaxCharacters', { count: 100 })),
      lastName: trimmedString
        .required(t('Errors.Required'))
        .max(100, t('Errors.MaxCharacters', { count: 100 })),
      email: trimmedString
        .required(t('Errors.Required'))
        .email(t('Errors.NotValidEmail'))
        .max(254, t('Errors.MaxCharacters', { count: 254 })),
      occupation: trimmedString.max(
        100,
        t('Errors.MaxCharacters', { count: 100 })
      ),
      workingPlace: trimmedString.max(
        100,
        t('Errors.MaxCharacters', { count: 100 })
      ),
      education: yup.number().required(t('Errors.Required')),
      graduatedFrom: trimmedString.max(
        200,
        t('Errors.MaxCharacters', { count: 200 })
      ),
      graduationYear: trimmedString.matches(
        yearRegExp,
        t('common:Errors.NotValidDate')
      ),
      phone: trimmedString
        .max(50, t('Errors.MaxCharacters', { count: 50 }))
        .matches(phoneRegExp, t('Errors.NotValidPhoneNumber')),
      contractNumber: trimmedString,
    }),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={
        page === 'company' ? companyValidationSchema : userValidationSchema
      }
      onSubmit={(values) =>
        handleSubmit({
          ...trimObjectProperties(values),
          adminUser: {
            ...trimObjectProperties(values.adminUser),
            graduationYear: values.adminUser.graduationYear || undefined,
          },
        })
      }
    >
      {({ isSubmitting }) => (
        <StyledForm noValidate>
          {page === 'company' ? (
            <>
              <Typography variant="h2">
                <div>1/2</div>
                <FormHeading>
                  {t('Registration.CompanyInfoHeading')}
                </FormHeading>
              </Typography>
              <RegistrationCompanyForm />
            </>
          ) : (
            <>
              <Typography variant="h2">
                <div>2/2</div>
                <FormHeading>{t('Registration.UserInfoHeading')}</FormHeading>
              </Typography>
              <RegistrationUserForm
                onBackClick={() => onSetPage('company')}
                isSubmitting={isSubmitting}
              />
            </>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

export default RegistrationContent;
