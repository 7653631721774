import { InstanceInformation } from 'types/instances';
import { get } from 'apis/api';

async function getCurrentInstance() {
  let instanceInfo: InstanceInformation | undefined;

  const storageInstanceInfo = sessionStorage.getItem('current-instance');
  if (storageInstanceInfo) {
    try {
      instanceInfo = JSON.parse(storageInstanceInfo) as InstanceInformation;
    } catch {}
  }

  if (!instanceInfo || !instanceInfo.defaultLanguage) {
    const response = await get('/instances/current');
    instanceInfo = (await response.json()) as InstanceInformation;
  }

  sessionStorage.setItem('current-instance', JSON.stringify(instanceInfo));

  return {
    defaultLanguage: instanceInfo.defaultLanguage.toLowerCase(),
    availableLanguages: instanceInfo.availableLanguages.map((lang) =>
      lang.toLowerCase()
    ),
  } as InstanceInformation;
}

export { getCurrentInstance };
