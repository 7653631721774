import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Formik, FormikProps, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';

import { defaultBorder } from 'styles';

import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import Button from 'components/controls/button/Button';
import Drawer from 'components/page/Drawer';
import { useShowAlert } from 'hooks/useShowAlert';
import { ValidationError } from 'types/errors';

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

type inputSectionProps = {
  inputSectionPadding: boolean;
};

const StyledInputSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 2rem
    ${(props: inputSectionProps) =>
      props.inputSectionPadding ? '1.5rem' : '0'};
  & .MuiFormControl-root {
    margin-bottom: 2rem;
  }
`;

const StyledButtonSection = styled.div`
  display: flex;
  padding-top: 1rem;
  justify-content: space-between;
  border-top: ${defaultBorder};
  padding: 1rem 1.5rem;

  & button ~ button {
    margin-left: 1rem;
  }
`;

type FormDrawerProps<T> = {
  onSave(values: T): Promise<void>;
  onClose(): void;
  initialValues: T;
  validationSchema: yup.SchemaOf<T>;
  children(props: FormikProps<T>): React.ReactNode;
  inputSectionPadding?: boolean;
  open: boolean;
  header: string;
  width?: string;
};

const FormDrawer = <T,>({
  onSave,
  onClose,
  initialValues,
  validationSchema,
  children,
  inputSectionPadding = true,
  open,
  header,
  width,
}: FormDrawerProps<T>) => {
  const { t } = useTranslation('common');
  const { showError } = useShowAlert();

  const [confirmationDrawer, setConfirmationDrawer] = useState(false);

  const handleClose = (formikProps: FormikProps<T>) => {
    if (formikProps.dirty) {
      setConfirmationDrawer(true);
    } else {
      onClose();
      formikProps.setErrors({});
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, helpers) => {
          try {
            await onSave(values);
            helpers.resetForm();
          } catch (err) {
            if (err instanceof ValidationError) {
              const validationError = err as ValidationError;
              if (validationError.errors.length) {
                showError(t(`ServerErrors.${validationError.errors[0].code}`));
              }
            } else {
              showError(err);
            }
          }
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Drawer
              width={width}
              header={header}
              open={open}
              onClose={() => handleClose(props)}
            >
              <StyledForm noValidate>
                <StyledInputSection inputSectionPadding={inputSectionPadding}>
                  {children({
                    ...props,
                  })}
                </StyledInputSection>
                <StyledButtonSection>
                  <Button color="tertiary" onClick={() => handleClose(props)}>
                    {t('Cancel')}
                  </Button>

                  <Button
                    color="primary"
                    type="submit"
                    disabled={props.isSubmitting}
                  >
                    {t('Save')}
                  </Button>
                </StyledButtonSection>
              </StyledForm>
            </Drawer>
            <ConfirmationDialog
              open={confirmationDrawer}
              title={t('ConfirmationDialog.UnsavedChangesTitle')}
              description={t('ConfirmationDialog.UnsavedChangesDescription')}
              onCancel={() => setConfirmationDrawer(false)}
              onClose={() => setConfirmationDrawer(false)}
              onContinue={() => {
                props.resetForm();
                setConfirmationDrawer(false);
                onClose();
              }}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default FormDrawer;
