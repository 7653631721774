import { FC, ReactElement } from 'react';
import { useField } from 'formik';

import { Tag } from 'types/tags';

import TagMultiselect, {
  TagMultiselectProps,
} from 'components/controls/select/TagMultiselect';

const FormTagSelect: FC<
  { name: string } & Omit<
    TagMultiselectProps,
    'name' | 'value' | 'onChange' | 'onBlur' | 'multiple'
  >
> = ({ name, options, ...rest }): ReactElement => {
  const [, meta, helpers] = useField<Tag['id'][]>(name);

  const { setValue, setTouched } = helpers;
  const { value } = meta;

  return (
    <TagMultiselect
      {...rest}
      options={options}
      value={value.reduce<Tag[]>((arr, val) => {
        const tag = options.find((opt) => opt.id === val);
        tag && arr.push(tag);
        return arr;
      }, [])}
      onChange={(tags) => setValue(tags.map((tag) => tag.id))}
      onBlur={() => setTouched(true)}
    />
  );
};

export default FormTagSelect;
