import { useCallback, useContext } from 'react';

import { AuthDispatchContext } from 'providers/AuthProvider';
import { Credentials } from 'types/auth';

import { login as authLogin, logout as authLogout } from 'apis/auth';

const useLogin = () => {
  const dispatch = useContext(AuthDispatchContext);

  const login = useCallback(
    async (credentials: Credentials) => {
      const loginResult = await authLogin(credentials);
      dispatch({ type: 'LOGIN_SUCCESS', payload: loginResult });
    },
    [dispatch]
  );

  const logout = useCallback(async () => {
    await authLogout();
    dispatch({ type: 'LOGOUT' });
  }, [dispatch]);

  return { login, logout };
};

export default useLogin;
