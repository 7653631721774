import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import { useField } from 'formik';
import { FC, ReactElement, useState, useEffect } from 'react';
import { fieldBorderRadius, colors, fontSize } from 'styles';
import styled from 'styled-components';
import { FormHelperText } from '@material-ui/core';
import draftToHtml from 'draftjs-to-html';
import {
  EditorState,
  convertToRaw,
  ContentState,
  SelectionState,
  Modifier,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

type EditorWrapperProps = {
  required: boolean;
};

const EditorWrapper = styled.div`
  .rdw-editor-main {
    height: 10rem;
    border: 1px solid
      ${({ required }: EditorWrapperProps) =>
        required ? colors.danger.text : colors.editor.border};
    border-radius: ${fieldBorderRadius};
    padding: 0rem 1rem;
    &:hover {
      border: 1px solid
        ${({ required }: EditorWrapperProps) =>
          required ? colors.danger.text : colors.editor.hoverBorder};
    }
    &:focus-within {
      border: 2px solid
        ${({ required }: EditorWrapperProps) =>
          required ? colors.danger.text : colors.editor.hoverBorder};
    }
  }

  .DraftEditor-root {
    font-size: ${fontSize.mainText};
    height: auto;
  }
  .public-DraftEditor-content {
    margin-top: 0.75rem;
  }

  .public-DraftStyleDefault-block {
    margin: 0.5rem 0;
  }

  .public-DraftEditorPlaceholder-root {
    display: flex;
    color: ${({ required }: EditorWrapperProps) =>
      required ? colors.danger.text : colors.editor.placeholder};
    &::after {
      content: '*';
      color: ${colors.danger.text};
    }
  }

  .Mui-required {
    color: ${colors.danger.text};
    margin-left: 1rem;
  }
`;

const stringHtmlToEditorState = (stringHtml: string): EditorState => {
  const contentBlock = htmlToDraft(stringHtml);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  return EditorState.createEmpty();
};

const trimEditorContent = (currentEditorState: EditorState) => {
  const currentContent = currentEditorState.getCurrentContent();
  const newContent = currentContent
    .getBlockMap()
    .reduce((accumulator, block) => {
      if (block) {
        const key = block.getKey();
        const text = block.getText();
        const trimmedLeft = text.trimLeft();
        const trimmedRight = text.trimRight();
        const offset = text.length - trimmedLeft.length;

        const textToReplaceLeft = new SelectionState({
          anchorKey: key,
          focusKey: key,
          anchorOffset: 0,
          focusOffset: offset,
        });

        const leftTrimmedContent = Modifier.replaceText(
          accumulator as ContentState,
          textToReplaceLeft,
          ''
        );

        const textToReplaceRight = new SelectionState({
          anchorKey: key,
          focusKey: key,
          anchorOffset: trimmedRight.length - offset,
          focusOffset: text.length - offset,
        });

        return Modifier.replaceText(leftTrimmedContent, textToReplaceRight, '');
      }
      return currentContent;
    }, currentContent);

  return newContent;
};

type EditorProps = {
  name: string;
  placeholder: string;
  required?: boolean;
};

//TODO: console warning
const Editor: FC<EditorProps> = ({
  name,
  placeholder,
  required,
}): ReactElement => {
  const [, meta, helpers] = useField<string>(name);

  const { value, error, touched } = meta;
  const { setValue, setTouched } = helpers;

  const initialEditorState = stringHtmlToEditorState(value);

  const [editorState, setEditorState] = useState<EditorState>(
    initialEditorState
  );

  const setFormikValue = () => {
    const trimmedEditorContent = trimEditorContent(editorState);
    setValue(
      trimmedEditorContent.getPlainText().trim()
        ? draftToHtml(convertToRaw(trimmedEditorContent))
        : ''
    );
  };

  useEffect(() => {
    setEditorState(stringHtmlToEditorState(value));
  }, [value]);

  const shouldHidePlaceHolder = ![
    'ordered-list-item',
    'unordered-list-item',
  ].includes(editorState.getCurrentContent().getBlockMap().first().getType());

  return (
    <EditorWrapper required={Boolean(required && error && touched)}>
      <DraftEditor
        toolbar={{
          options: ['inline', 'list'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          list: { options: ['unordered', 'ordered'] },
        }}
        onBlur={() => {
          setTouched(true);
          setFormikValue();
        }}
        editorState={editorState}
        onEditorStateChange={(editorState) => setEditorState(editorState)}
        placeholder={shouldHidePlaceHolder ? placeholder + ' ' : undefined}
        stripPastedStyles={true}
      />
      {required && error && touched ? (
        <FormHelperText margin="dense" required={required}>
          {error}
        </FormHelperText>
      ) : null}
    </EditorWrapper>
  );
};

export default Editor;
