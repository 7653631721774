import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

import { defaultLng, supportedLngsCodes } from 'constants/languages';
import InstanceLanguageDetector from 'utils/InstanceLanguageDetector';

const isDevelopment = process.env.NODE_ENV === 'development';

i18n
  .use(Backend)
  .use(InstanceLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: isDevelopment,

    fallbackLng: defaultLng,
    load: 'languageOnly',
    cleanCode: true,
    supportedLngs: supportedLngsCodes,
    defaultNS: 'common',
    ns: 'common',

    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          prefix: 'i18n_',
          defaultVersion: '',
          // versions: {},
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    detection: {
      lookupLocalStorage: 'i18n-lng',
    },

    react: {
      useSuspense: true,
    },
  });

export default i18n;
