import { FC, ReactElement } from 'react';
import { useField } from 'formik';

import DatePicker, {
  DatePickerProps,
} from 'components/controls/date-pickers/DatePicker';

const FormDatePicker: FC<
  { name: string } & Omit<
    DatePickerProps,
    'name' | 'value' | 'onChange' | 'onBlur'
  >
> = ({ name, ...rest }): ReactElement => {
  const [, meta, helpers] = useField<DatePickerProps['value']>(name);

  const { setValue, setTouched } = helpers;
  const { error, touched, value } = meta;

  return (
    <DatePicker
      {...rest}
      id={`form-field-${name}`}
      value={value}
      onChange={(date) => {
        setValue(date);
      }}
      onBlur={() => setTouched(true)}
      error={Boolean(touched && error)}
      helperText={touched && error}
    />
  );
};

export default FormDatePicker;
