import { InitOptions, LanguageDetectorAsyncModule, Services } from 'i18next';

import { getCurrentInstance } from 'apis/instance';

const localStorageAvailable = () => {
  let hasLocalStorageSupport: boolean | null = null;

  try {
    hasLocalStorageSupport = window.localStorage !== null;
    const testKey = 'test';
    window.localStorage.setItem(testKey, 'foo');
    window.localStorage.removeItem(testKey);
  } catch (e) {
    hasLocalStorageSupport = false;
  }
  return hasLocalStorageSupport;
};

class InstanceLanguageDetector implements LanguageDetectorAsyncModule {
  static type: 'languageDetector';
  type: 'languageDetector';
  async: true;
  options: { lookupLocalStorage?: string };
  services: Services;

  constructor(
    services: Services,
    options = { lookupLocalStorage: 'i18nextLng' }
  ) {
    this.type = 'languageDetector';
    this.async = true;
    this.services = services;
    this.options = options;
  }

  init(services: Services, options: object, i18nOptions: InitOptions = {}) {
    this.services = services;
    this.options = options;
  }

  async detect(callback: (locale: string | string[] | undefined) => void) {
    let found = [];

    if (this.options.lookupLocalStorage && localStorageAvailable()) {
      const lng = window.localStorage.getItem(this.options.lookupLocalStorage);
      if (lng) found.push(lng);
    }

    try {
      var instanceInfo = await getCurrentInstance();
      if (instanceInfo && instanceInfo.defaultLanguage) {
        found.push(instanceInfo.defaultLanguage);
      }
    } catch (e) {
      // todo: log error?
      // eslint-disable-next-line no-console
      console.error(e);
    }

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        for (let i = 0; i < navigator.languages.length; i++) {
          found.push(navigator.languages[i]);
        }
      }
      if (navigator.language) {
        found.push(navigator.language);
      }
    }

    callback(found.length > 0 ? found : undefined);
  }

  cacheUserLanguage(lng: string) {
    if (this.options.lookupLocalStorage && localStorageAvailable()) {
      window.localStorage.setItem(this.options.lookupLocalStorage, lng);
    }
  }
}

InstanceLanguageDetector.type = 'languageDetector';

export default InstanceLanguageDetector;
