import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonDialog from './ButtonDialog';
import Button from 'components/controls/button/Button';

type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  description?: string;
  onCancel(): void;
  onContinue(): void;
  onClose?(): void;
};

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  onCancel,
  onContinue,
  ...rest
}): ReactElement => {
  const { t } = useTranslation('common');

  return (
    <ButtonDialog
      buttons={
        <>
          <Button color="tertiary" onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button color="danger" onClick={() => onContinue()}>
            {t('Continue')}
          </Button>
        </>
      }
      {...rest}
    />
  );
};

export default ConfirmationDialog;
