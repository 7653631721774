export const size = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1600,
};

export const device = (Object.keys(size) as Array<keyof typeof size>).reduce(
  (acc, key) => {
    acc[key] = (style: String) =>
      `@media (min-width: ${size[key]}px) { ${style} }`;
    return acc;
  },
  {} as { [index: string]: Function }
);
