import styled, { css } from 'styled-components';

import { ReactComponent as SessionsIcon } from 'assets/icons/sessions.svg';
import { ReactComponent as ActiveSessionsIcon } from 'assets/icons/activeSessions.svg';
import { ReactComponent as BatchesIcon } from 'assets/icons/batches.svg';
import { ReactComponent as ManualIcon } from 'assets/icons/manual.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { ReactComponent as AsebaIcon } from 'assets/icons/aseba.svg';

import {
  Dashboard as DashboardIcon,
  InsertChart,
  LocalOffer,
  Mail,
  Payment,
  PeopleAlt,
  Person,
} from '@material-ui/icons';
import { colors } from 'styles';

const customIconStyle = css`
  fill: currentColor;
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
  user-select: none;
`;

export const Dashboard = styled(DashboardIcon)`
  color: ${colors.icons.dashboard};
`;

export const Respondents = styled(PeopleAlt)`
  color: ${colors.icons.respondents};
`;

export const Sessions = styled(SessionsIcon)`
  color: ${colors.icons.sessions};
  ${customIconStyle};
`;

export const ActiveSessions = styled(ActiveSessionsIcon)`
  color: ${colors.icons.sessions};
  ${customIconStyle};
`;

export const Results = styled(InsertChart)`
  color: ${colors.icons.results};
`;

export const Credits = styled(Payment)`
  color: ${colors.icons.credits};
`;

export const Batches = styled(BatchesIcon)`
  color: ${colors.icons.batches};
  ${customIconStyle};
`;

export const Templates = styled(Mail)`
  color: ${colors.icons.templates};
`;

export const Users = styled(Person)`
  color: ${colors.icons.users};
`;

export const Manual = styled(ManualIcon)`
  color: ${colors.icons.manual};
  ${customIconStyle};
`;

export const Tags = styled(LocalOffer)`
  color: ${colors.icons.tags};
`;

export const Export = styled(ExportIcon)`
  ${customIconStyle};
`;

export const Aseba = styled(AsebaIcon)`
  color: ${colors.icons.aseba};
  ${customIconStyle};
`;
