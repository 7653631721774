import { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { Drawer as MuiDrawer, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { colors, defaultBorder } from 'styles';

const wrapper = css`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * ~ * {
    margin-left: 0.5rem;
  }
`;

const StyledMuiDrawer = styled(MuiDrawer)`
  & .MuiDrawer-paper {
    width: ${(props: { width?: string }) =>
      props.width ? props.width : 'default'};
  }
`;

const DrawerHeader = styled.header`
  ${wrapper};
  border-bottom: ${defaultBorder};
  flex: 0 0 auto;
  & .MuiButtonBase-root {
    color: ${colors.secondaryText};
  }
`;

const ContentSection = styled.section`
  min-height: 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

type DrawerProps = {
  header: string;
  onClose?(): void;
  open?: boolean;
  children?: React.ReactNode;
  width?: string;
};

const Drawer: FC<DrawerProps> = ({
  open,
  header,
  onClose,
  children,
  ...rest
}): ReactElement => {
  return (
    <StyledMuiDrawer anchor={'right'} open={open} {...rest}>
      <DrawerHeader>
        <Typography variant="h1">{header}</Typography>
        <IconButton onClick={() => onClose && onClose()}>
          <Close />
        </IconButton>
      </DrawerHeader>
      <ContentSection>{children}</ContentSection>
    </StyledMuiDrawer>
  );
};

Drawer.defaultProps = {
  open: false,
  onClose: () => {},
};

export default Drawer;
