import styled from 'styled-components';

import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
} from '@material-ui/core';

import { backgrounds, colors, fontWeight } from 'styles';

type ButtonColor =
  | 'default'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger';

interface ButtonProps extends Omit<MaterialButtonProps, 'color'> {
  color?: ButtonColor;
  component?: string | React.ElementType;
}

const handleButtonColor = (color: ButtonColor, disabled?: boolean) => {
  switch (color) {
    case 'primary':
      return `
          background-color: ${colors.primaryButton.background}
        ;
        color: ${colors.primaryButton.text};
        &:hover {
            background-color: ${colors.primaryButton.hover};
        }
      `;
    case 'secondary':
      return `
        ${!disabled && `background: ${backgrounds.gradientBlue};`}
        color: ${colors.secondaryButton.text};
        &:hover {
            background: ${backgrounds.gradientBlue};
        }
      `;
    case 'tertiary':
      return `
        background-color: ${colors.tertiaryButton.background};
        color: ${colors.tertiaryButton.text};
        &:hover {
            background-color: ${colors.tertiaryButton.hover};
        }
      `;
    case 'danger':
      return `
        background-color: ${colors.danger.background};
        color: ${colors.danger.text};
        ${!disabled && `border: 1px solid ${colors.danger.border};`}
        &:hover {
            background-color: ${colors.danger.hover};
            color: ${colors.danger.hoverText};
        }
      `;
  }
};

const Button = styled((props: ButtonProps) => {
  const { color, ...rest } = props;
  return <MaterialButton {...rest} />;
})`
  font-weight: ${fontWeight.bold};
  text-transform: none;
  & .MuiButton-label > * ~ * {
    margin-left: 0.5em;
  }

  ${(props) =>
    props.variant !== 'text'
      ? `
      min-width: 8rem;
      &:hover {
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 12%),
                    0px 4px 5px 0px rgba(0, 0, 0, 14%),
                    0px 2px 4px -1px rgba(0, 0, 0, 20%);
      }
      ${handleButtonColor(props.color ?? 'primary', props.disabled)}
      `
      : `
      &:hover {
        background: none;
        text-decoration: underline;
        color: ${colors.textButton.hoverText}
      }`}
`;

Button.defaultProps = {
  color: 'primary',
};

export default Button;
