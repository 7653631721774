import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Formik, FormikProps, Form as FormikForm } from 'formik';
import * as yup from 'yup';

import { defaultBorder } from 'styles';

import Button from 'components/controls/button/Button';

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

type inputSectionProps = {
  inputSectionPadding: boolean;
};

const StyledInputSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 2rem
    ${(props: inputSectionProps) =>
      props.inputSectionPadding ? '1.5rem' : '0'};
  & .MuiFormControl-root {
    margin-bottom: 2rem;
  }
`;

type buttonSectionProps = {
  singleButton: boolean;
};

const StyledButtonSection = styled.div`
  display: flex;
  padding-top: 1rem;
  justify-content: ${(props: buttonSectionProps) =>
    props.singleButton ? 'center' : 'space-between'};

  border-top: ${defaultBorder};
  padding: 1rem 1.5rem;

  & button ~ button {
    margin-left: 1rem;
  }
`;

type FormProps<T> = {
  onSave(values: T): void;
  onCancel?(): void;
  initialValues: T;
  validationSchema: yup.SchemaOf<T>;
  children(props: FormikProps<T>): React.ReactNode;
  inputSectionPadding?: boolean;
  buttonLabels?: {
    save?: string;
    cancel?: string;
  };
};

const Form = <T,>({
  onSave,
  onCancel,
  initialValues,
  validationSchema,
  children,
  inputSectionPadding = true,
  buttonLabels,
  ...rest
}: FormProps<T>) => {
  const { t } = useTranslation('common');

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={validationSchema}
    >
      {(props) => (
        <StyledForm noValidate {...rest}>
          <StyledInputSection inputSectionPadding={inputSectionPadding}>
            {children({
              ...props,
            })}
          </StyledInputSection>
          <StyledButtonSection singleButton={!onCancel}>
            {onCancel && (
              <Button color="tertiary" onClick={() => onCancel()}>
                {buttonLabels ? buttonLabels.cancel : t('Cancel')}
              </Button>
            )}
            <Button color="primary" type="submit" disabled={props.isSubmitting}>
              {buttonLabels ? buttonLabels.save : t('Save')}
            </Button>
          </StyledButtonSection>
        </StyledForm>
      )}
    </Formik>
  );
};

export default Form;
