import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';

import { getDateFormatString, startOfDay } from 'utils/dateFunctions';

type PickerProps = {
  id?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  label?: string;
  format?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  size?: 'medium' | 'small';
};

export type DatePickerProps = PickerProps &
  Omit<KeyboardDatePickerProps, keyof PickerProps>;

const DatePicker: FC<DatePickerProps> = ({
  id,
  value,
  onChange,
  label,
  format = getDateFormatString(),
  variant,
  size = 'medium',
  placeholder,
  ...rest
}): ReactElement => {
  const { t } = useTranslation('common');

  return (
    <KeyboardDatePicker
      id={id}
      size={size}
      variant="inline"
      autoOk
      inputVariant={variant}
      value={value}
      onChange={(date) => onChange(date && startOfDay(date))}
      label={label}
      format={format}
      placeholder={placeholder || format}
      invalidDateMessage={t('Dates.InvalidDateFormatError')}
      maxDateMessage={t('Dates.MaxDateError')}
      minDateMessage={t('Dates.MinDateError')}
      {...rest}
    />
  );
};

export default DatePicker;
