export const defaultLng = 'en';

type Language = {
  code: string;
  name: string;
};

export const supportedLngs: Language[] = [
  { code: 'en', name: 'English' },
  { code: 'hr', name: 'Hrvatski' },
  { code: 'sr', name: 'Српски' },
];

export const supportedLngsCodes = supportedLngs.map((lng) => lng.code);
