import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import queryString from 'query-string';

import { colors } from 'styles';
import { paths } from 'constants/paths';
import { ValidationError } from 'types/errors';
import { post } from 'apis/api';

import RecoverPasswordForm from './RecoverPasswordForm';
import { ForgotPasswordParameters } from 'types/auth';
import SetNewPasswordForm, {
  SetNewPasswordFormParameters,
} from './SetNewPasswordForm';
import PanePageLayout from 'components/page/PanePageLayout';

const PasswordRecoveryError = styled.p`
  margin: 0.5rem 0 0;
  text-align: center;
  color: ${colors.error};
`;

const ExplanationText = styled.p`
  margin: 0.5rem 0 0;
  text-align: center;
`;

const useForgotPasswordParams = () => {
  const { search } = useLocation();
  const { email, token } = queryString.parse(search, {
    decode: false,
  });

  return {
    email: email as string | null,
    token: token as string | null,
  };
};

const ForgotPassword = () => {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();

  const [recoverPasswordError, setError] = useState<string | null>(null);
  const [linkSentTo, setLinkSent] = useState<string | null>(null);

  const history = useHistory();

  const { email, token } = useForgotPasswordParams();

  const changePassword =
    pathname === paths.app.changePassword && !!email && !!token;

  const handleRecoverPassword = async (
    parameters: ForgotPasswordParameters
  ) => {
    try {
      setError(null);

      await post(`/authentication/forgot-password`, parameters);
      setLinkSent(parameters.email);
    } catch (err) {
      let errorMessage;

      if (err instanceof ValidationError) {
        const validationError = err as ValidationError;

        if (validationError.errors.length) {
          errorMessage = t(`ServerErrors.${validationError.errors[0].code}`);
        }
      }

      setError(
        errorMessage ?? t('RecoverPassword.FailedToRecoverPasswordError')
      );
    }
  };

  const handleSetPassword = async (
    parameters: SetNewPasswordFormParameters
  ) => {
    try {
      setError(null);

      if (token) {
        await post('/authentication/change-forgotten-password', {
          newPassword: parameters.newPassword,
          token,
          email,
        });
        history.push(paths.app.login);
      }
    } catch (err) {
      let errorMessage;

      if (err instanceof ValidationError) {
        const validationError = err as ValidationError;

        if (validationError.errors.length) {
          errorMessage = t(`ServerErrors.${validationError.errors[0].code}`);
        }
      }

      setError(errorMessage ?? t('RecoverPassword.FailedToSetPasswordError'));
    }
  };

  return (
    <PanePageLayout heading={t('RecoverPassword.Heading')}>
      {!changePassword &&
        (!linkSentTo ? (
          <RecoverPasswordForm onRecover={handleRecoverPassword} />
        ) : (
          <ExplanationText>
            {t('RecoverPassword.LinkSentExplanation', { email: linkSentTo })}
          </ExplanationText>
        ))}
      {changePassword && (
        <SetNewPasswordForm onSetPassword={handleSetPassword} />
      )}
      {recoverPasswordError && (
        <PasswordRecoveryError>{recoverPasswordError}</PasswordRecoveryError>
      )}
    </PanePageLayout>
  );
};

export default ForgotPassword;
