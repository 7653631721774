const background = {
  gradientDarkBlue:
    'linear-gradient(258.96deg, #005DC2 -1.19%, #003589 88.14%);',
  gradientBlue: 'linear-gradient(258.96deg, #07B3F4 -1.19%, #0065DB 88.14%);',
  gradientLightBlue:
    'linear-gradient(141.56deg, #019FDB 19.01%, #03ACEC 77.88%);',
  gradientGreen: 'linear-gradient(140.46deg, #8ce272 5.14%, #02d290 95.16%);',
};

export default background;
