import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { FormTextField } from 'components/form';
import Button from 'components/controls/button/Button';
import { ForgotPasswordParameters } from 'types/auth';

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  min-width: 16rem;

  & .MuiTextField-root,
  .MuiButton-root {
    margin-top: 1.5rem;
  }

  & a {
    margin-top: 0.5rem;
  }
`;

type RecoverPasswordFormProps = {
  onRecover: (parameters: ForgotPasswordParameters) => void;
};

const RecoverPasswordForm: FC<RecoverPasswordFormProps> = ({
  onRecover,
}): ReactElement => {
  const { t } = useTranslation('common');

  const initialValues: ForgotPasswordParameters = {
    email: '',
  };

  const recoverPasswordSchema: yup.SchemaOf<ForgotPasswordParameters> = yup.object(
    {
      email: yup
        .string()
        .trim()
        .email(t('common:Errors.NotValidEmail'))
        .max(254, t('Errors.MaxCharacters', { count: 254 }))
        .required(t('Errors.Required')),
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={recoverPasswordSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => onRecover({ email: values.email.trim() })}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <FormTextField
            name="email"
            label={t('RecoverPassword.EmailLabel')}
            size="small"
          />
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {t('RecoverPassword.SendLinkButton')}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default RecoverPasswordForm;
