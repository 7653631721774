import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';
import { colors, zIndex } from 'styles';

const ProgressWrapper = styled.div<{ $overlay?: boolean }>`
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;

  ${({ $overlay }) =>
    $overlay &&
    `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndex.overContent};
    background: ${colors.background}7F;
  `}
`;

const Progress: FC<{ overlay?: boolean }> = ({ overlay }): ReactElement => (
  <ProgressWrapper $overlay={overlay}>
    <CircularProgress />
  </ProgressWrapper>
);

export default Progress;
