import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import queryString from 'query-string';

import { colors } from 'styles';
import { paths } from 'constants/paths';

import CompleteRegistrationForm, {
  RegistrationFormParameters,
} from './components/CompleteRegistrationForm';
import { ValidationError } from 'types/errors';
import { RegisterParameters } from 'types/auth';
import { post } from 'apis/api';
import PanePageLayout from 'components/page/PanePageLayout';

const ExplanationText = styled.p`
  margin: 0.5rem 0 0;
  text-align: center;
`;

const SignInError = styled.p`
  margin: 0.5rem 0 0;
  text-align: center;
  color: ${colors.error};
`;

const useRegistrationParams = () => {
  const { search } = useLocation();
  const { email, token } = queryString.parse(search, { decode: false });

  return { email: email as string | null, token: token as string | null };
};

const CompleteRegistration = () => {
  const { t } = useTranslation('common');
  const [passwordError, setError] = useState<string | null>(null);
  const history = useHistory();

  const { email, token } = useRegistrationParams();

  const handleSetPassword = async ({
    email,
    newPassword,
  }: RegistrationFormParameters) => {
    try {
      setError(null);

      if (token) {
        const registrationParameters: RegisterParameters = {
          email: email,
          token: token,
          newPassword: newPassword,
        };

        await post(
          '/authentication/complete-registration',
          registrationParameters
        );

        history.push(paths.app.login);
      }
    } catch (err) {
      let errorMessage;

      if (err instanceof ValidationError) {
        const validationError = err as ValidationError;

        if (validationError.errors.length) {
          errorMessage = t(`ServerErrors.${validationError.errors[0].code}`);
        }
      }

      setError(
        errorMessage ?? t('CompleteRegistration.FailedToSetPasswordError')
      );
    }
  };

  return (
    <PanePageLayout heading={t('CompleteRegistration.Heading')}>
      <ExplanationText>
        {t('CompleteRegistration.CompleteRegistrationText')}
      </ExplanationText>
      <CompleteRegistrationForm
        email={email ?? ''}
        onSetPassword={handleSetPassword}
      />
      {passwordError && <SignInError>{passwordError}</SignInError>}
    </PanePageLayout>
  );
};

export default CompleteRegistration;
