import i18n from 'i18next';

import { apiBaseUrl } from 'constants/urls';
import { ErrorCodes, ValidationError } from 'types/errors';

const credentials =
  process.env.NODE_ENV === 'development' ? 'include' : 'same-origin';

const handleErrors = async (response: Response) => {
  if (!response.ok) {
    if (response.status === ErrorCodes.ValidationError) {
      const error = new ValidationError(response.statusText);
      error.errors = (await response.json()).errors;
      throw error;
    } else if (response.status === ErrorCodes.Forbidden) {
      const error = new Error(response.statusText);
      error.status = response.status;
      throw error;
    }
    throw new Error(response.statusText);
  }
  return response;
};

export const fetcher = (url: string) =>
  get(url).then((response) => response.json());

const normalizeUrl = (url: string): string =>
  url.startsWith('http')
    ? url
    : apiBaseUrl + (url.startsWith('/') ? url : `/${url}`);

const makeHeaders = (
  method: 'get' | 'post' | 'delete',
  isMultipart: boolean = false
): HeadersInit => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  if (i18n.languages && i18n.languages.length) {
    headers.append('Accept-Language', i18n.languages.join(','));
  }

  if ((method === 'post' || method === 'delete') && !isMultipart) {
    headers.append('Content-Type', 'application/json');
  }

  return headers;
};

export const get = async (url: string) => {
  return fetch(normalizeUrl(url), {
    headers: makeHeaders('get'),
    credentials,
  }).then(handleErrors);
};

export const post = async (url: string, data?: object) => {
  const isMultipart = data instanceof FormData;

  return fetch(normalizeUrl(url), {
    method: 'post',
    headers: makeHeaders('post', isMultipart),
    body: data && (isMultipart ? (data as FormData) : JSON.stringify(data)),
    credentials,
  }).then(handleErrors);
};

export const del = async (url: string, data?: object) => {
  return fetch(normalizeUrl(url), {
    method: 'delete',
    headers: makeHeaders('delete'),
    body: data ? JSON.stringify(data) : null,
    credentials,
  }).then(handleErrors);
};
